@use "../../src/styles/abstracts/variables" as *;
@use "../../src/styles/abstracts/mixins" as *;

.notFound {
  background-color: $col-white;
  height: 100vh;
  width: 100%;
  @include flex-row;
  position: relative;

  &__heading {
    position: relative;
    width: auto;
    height: auto;

    &-content {
      font-family: "Fahkwang", sans-serif;
      font-size: 18.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 24.375rem;
      letter-spacing: 0.03em;

      @include respond(big-tab) {
        font-size: 9rem;
        line-height: 10rem;
      }
    }
  }
}
